<template>
  <div class="loginbox">
    <div class="back-icon">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
    </div>
    <div class="loginBody-image">
       注册帐户
    </div>
    <div class="loginBody-found">
		<div class="form_item">
			<div class="txtcolor form_label">姓名：</div>
			<div class="form_body">
				<input placeholder="请输入姓名" v-model="registerform.realname"/>
			</div>
		</div>
		<div class="form_item">
			<div class="txtcolor form_label">会员帐户：</div>
			<div class="form_body">
				<input placeholder="请输入您的会员帐户" v-model="registerform.username"/>
			</div>
		</div>
		<div class="form_item">
			<div class="txtcolor form_label">登录密码：</div>
			<div class="form_body">
				<input placeholder="请输入您的登录密码" type="password" v-model="registerform.password"/>
			</div>
		</div>
		<div class="form_item">
			<div class="txtcolor form_label">重复密码:</div>
			<div class="form_body">
				<input placeholder="请确认您的密码" type="password" v-model="registerform.repassword"/>
			</div>
		</div>
		<div class="form_item">
			<div class="txtcolor form_label">提现密码：</div>
			<div class="form_body">
				<input placeholder="请输入提现密码" type="password" v-model="registerform.txpass"/>
			</div>
		</div>
		<div class="form_item">
			<div class="txtcolor form_label">手机号:</div>
			<div class="form_body">
				<input placeholder="手机号" type="number" maxlength="11" v-model="registerform.phone"/>
			</div>
		</div>
		<div class="form_item" v-if="isyaoqingma==1">
			<div class="txtcolor form_label">邀请码:</div>
			<div class="form_body">
				<input placeholder="邀请码" maxlength="20" v-model="registerform.yaoqingma"/>
			</div>
		</div>
      <van-button
        class="mybtn"
        :loading="loading"
        :loading-text="$t('register.registering')"
        @click="newregister"
			:disabled="iscanlogin"
        >{{ $t('register.quickreg') }}</van-button
      >
	  
	  		<div
	  		  class="kefu"
	  		  @click="toserver"
	  		>
	  		  {{ $t('login.serverurl') }}
	  		</div>
			
			<div
			  class="kefu"
			  style="font-size: 1.1rem;"
			  @click="tologin"
			>
			  <span class="txtcolor">已经有账户了 </span> 立即{{$t('login.login')}}
			</div>
    </div>
    <!-- <van-row class="agreement">
      <van-checkbox v-model="checked" shape="square"
        >{{ $t('register.consent') }}
      </van-checkbox>
      <span style="color:#004ea3" @click="agreement">
        {{ $t('register.agreement') }}
      </span>
    </van-row> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
		isyaoqingma:0,
      lang: '86',
      option: [],
      form: {
        type: 0,
        username: '',
        password: '',
        repassword: ''
      },
	  registerform:{
		  type:2,
		  username:"",
		  username:"",
		  password:"",
		  repassword:"",
		  txpass:"",
		  phone:"",
		  yaoqingma:""
	  },
      type: 'password',
      loading: false,
      checked: true,
      eye: 'closed-eye',
      // 手机号验证
      phonerules: [
        {
          required: true,
          message: this.$t('register.required'),
          trigger: 'onBlur'
        },
        {
          validator: value => {
            return /^[0-9]*$/.test(value)
          },
          message: '请填写正确的手机号',
          trigger: 'onBlur'
        }
      ],
      // 用户名验证
      usernamerules: [
        {
          required: true,
          message: this.$t('register.required'),
          trigger: 'onBlur'
        },
        {
          validator: value => {
            return /^([a-zA-Z]|[0-9])*$/.test(
              value
            )
          },
          message: this.$t('register.yhmgs'),
          trigger: 'onBlur'
        }
      ],
      // 邮箱验证
      emailrules: [
        {
          required: true,
          message: this.$t('register.required'),
          trigger: 'onBlur'
        },
        {
          validator: value => {
            return /^([a-zA-Z]|[0-9])(\w)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(
              value
            )
          },
          message: this.$t('register.emailformat'),
          trigger: 'onBlur'
        }
      ],
      // 密码验证
      passrules: [
        {
          required: true,
          message: this.$t('register.required'),
          trigger: 'onBlur'
        }
      ],
      // 重复密码
      repassrules: [
        {
          validator: value => {
            return this.form.password === value
          },
          message: this.$t('register.eqpass'),
          trigger: 'onBlur'
        }
      ],
    }
  },
  computed:{
	iscanlogin(){
		return this.registerform.realname==''||
		this.registerform.username==''||
		this.registerform.password==''||
		this.registerform.repassword==''||
		this.registerform.txpass==''||
		this.registerform.phone==''
	}
  },
  created() {
    this.getinfo()
    this.getconfig()
  },
  methods: {
    async getconfig() {
      const { data } = await this.$http.get('/home/index/yanzhengma')
      if (data) {
        if (data.code === 200) {
          this.isyaoqingma = data.data
        }
      }
    },
    async getinfo() {
      const { data } = await this.$http.get('/home/index/getcode')
      if (data) {
        if (data.code === 200) {
          this.option = data.data
        }
      }
    },
    // 查看密码
    openeye(event) {
      if (this.type === 'password') {
        this.eye = 'eye'
        this.type = 'text'
      } else {
        this.eye = 'closed-eye'
        this.type = 'password'
      }
    },
	async newregister(){
		if(this.registerform.realname==''){
			return this.$toast.fail('请输入姓名')
		}
		if(this.registerform.username==''){
			return this.$toast.fail('请输入会员账户')
		}
		if(this.registerform.password==''){
			return this.$toast.fail('请输入登录密码')
		}
		if(this.registerform.repassword==''){
			return this.$toast.fail('请输入重复密码')
		}
		if(this.registerform.password!=this.registerform.repassword){
			return this.$toast.fail('两次密码输入不一致')
		}
		if(this.registerform.txpass==''){
			return this.$toast.fail('请输入提现密码')
		}
		if(this.registerform.phone==''){
			return this.$toast.fail('请输入手机号')
		}
		let formdata = Object.assign({},this.registerform);
		
		//  验证通过
		this.loading = true
		const { data } = await this.$http.post(
		  '/home/index/register',
		  formdata
		)
		if (data) {
		  if (data.code === 200) {
		    this.$toast.success(
		      this.getlang(data.msg, localStorage.getItem('lang'))
		    )
		    this.$router.push('/login')
		  } else {
		    this.$toast.fail(
		      this.getlang(data.msg, localStorage.getItem('lang'))
		    )
		  }
		}
		this.loading = false
	},
    // 快速注册
    register() {
      // 服务条款
      if (!this.checked) {
        this.$toast.fail(this.$t('register.readagreement'))
        return false
      }
      if (this.form.type === 0) {
        this.$refs.phoneform
          .validate()
          .then(async () => {
            //  验证通过
            this.loading = true
            const { data } = await this.$http.post(
              '/home/index/register',
              this.form
            )
            if (data) {
              if (data.code === 200) {
                this.$toast.success(
                  this.getlang(data.msg, localStorage.getItem('lang'))
                )
                this.$router.push('/login')
              } else {
                this.$toast.fail(
                  this.getlang(data.msg, localStorage.getItem('lang'))
                )
              }
            }
            this.loading = false
          })
          .catch(() => {
            // 验证失败
            return false
          })
      } else if (this.form.type === 2) {
        this.$refs.nameform
          .validate()
          .then(async () => {
            this.loading = true
            console.log(this.form)
            const { data } = await this.$http.post(
              '/home/index/register',
              this.form
            )
            if (data) {
              if (data.code === 200) {
                this.$toast.success(
                  this.getlang(data.msg, localStorage.getItem('lang'))
                )
                this.$router.push('/login')
              } else {
                this.$toast.fail(
                  this.getlang(data.msg, localStorage.getItem('lang'))
                )
              }
            }
            this.loading = false
          })
          .catch(() => {
            // 验证失败
            return false
          })
      } else {
        this.$refs.emailform
          .validate()
          .then(async () => {
            this.loading = true
            const { data } = await this.$http.post(
              '/home/index/register',
              this.form
            )
            if (data) {
              if (data.code === 200) {
                this.$toast.success(
                  this.getlang(data.msg, localStorage.getItem('lang'))
                )
                this.$router.push('/login')
              } else {
                this.$toast.fail(
                  this.getlang(data.msg, localStorage.getItem('lang'))
                )
              }
            }
            this.loading = false
          })
          .catch(() => {
            // 验证失败
            return false
          })
      }
    },
    // 服务条款
    agreement() {
      this.$router.push({ path: '/terms' })
    },
	tologin() {
      this.$router.push({ path: '/login' })
    },
    toserver() {
      window.location.href = this.serverurl
    },
    // 密码一致校验
    checkpass() {
      return this.form.password === this.form.repassword
    }
  }
}
</script>

<style lang="less" scoped>
  .kefu {
	text-align: left;
    font-size: 0.875rem;
    color: #ff571e;
	padding-top: 1.4rem;
  }
	.form_item{
		display: flex;
		align-items: center;
		position: relative;
		padding: 16px 0;
		padding-top: 30px;
		&::after{
			content: " ";
			position: absolute;
			left: 0;
			bottom: 0;
			right: 0;
			height: 0.026667rem;
			border-bottom: 0.026667rem solid #e5e5e5;
			color: #e5e5e5;
			-webkit-transform-origin: 0 100%;
			-ms-transform-origin: 0 100%;
			transform-origin: 0 100%;
			-webkit-transform: scaleY(0.5);
			-ms-transform: scaleY(0.5);
			transform: scaleY(0.5);
			z-index: 2;
		}
		.form_label{
			width: 7rem;
			font-size: 0.8rem;
			text-align: left;
		}
		.form_body{
			flex: 1;
			width: 10px;
			input{
				border: none;
				outline: none;
				background-color: transparent;
				color: #cad0e6;
				font-size: 0.8rem;
			}
		}
	}
	.van-cell-group{
		background-color:transparent;
	position: relative;
	}
/deep/ .van-cell {
    background-color: transparent;
}
/deep/ .van-tabs__nav {
    background-color: transparent;
}

/deep/ .van-field__control{
    color: #cad0e6;
}
/deep/ .van-dropdown-menu__title{
	color: #cad0e6;
}

/deep/ .van-cell::after,.van-cell-group::after{
	left: 0;
	content: " ";
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	height: 0.026667rem;
	border-bottom: 0.026667rem solid #e5e5e5;
	color: #e5e5e5;
	-webkit-transform-origin: 0 100%;
	-ms-transform-origin: 0 100%;
	transform-origin: 0 100%;
	-webkit-transform: scaleY(0.5);
	-ms-transform: scaleY(0.5);
	transform: scaleY(0.5);
	z-index: 2;
	    border-bottom: 0.026667rem solid #999a9b;
}
.loginbox {
  height: 100vh;
  width: 100%;
  background: #181e34;
  background-size: cover;
  position: relative;
  padding: 2.75rem 0.3rem 0 0.3rem;
  .back-icon {
    position: fixed;
    left: 4%;
    top: 2%;
    .van-icon {
      color: #004ea3;//后退键
    }
  }

  .loginBody-image {
    font-size: 2.2rem;
	color: #ff571e;
  }
  .loginBody-found {
    margin-left: 5%;
    width: 90%;
    padding-bottom: 3%;
    .van-dropdown-menu /deep/.van-dropdown-menu__bar {
      box-shadow: none;
      height: 1.5rem;
      background: transparent !important;
    }
    /deep/.van-tab {
      font-size: 1rem;
    }
    /deep/.van-tab--active {
      color: #004ea3;
      font-weight: 500;
    }
    .van-field {
      padding: 20px 0 20px 0;
      font-size: 1rem;
    }
    /deep/.van-field__label {
      width: auto;
    }
    /deep/ .van-icon,
    /deep/.van-field__right-icon {
      color: #004ea3;
      font-size: 1.2rem;
    }
    .van-tab {
      font-size: 1rem;
    }
    .van-tab--active {
      color: #004ea3;
    }
    // /deep/.van-tab__text--ellipsis {
    //   color: #004ea3;//选项字体
    // }
    .van-icon-clear {
      color: #b3b3b3;
      font-size: 1rem;
    }
    .van-field__control {
      padding-left: 5px;
    }

    .mybtn {
      margin-top: 30px;
      width: 100%;
      height: 3rem;
      font-size: 0.93333rem;
      color: #fff;
      background-color: #ff571e;//按钮
      border-radius: 0.5rem;
      border: none;
    }
  }

  .van-image__error,
  .van-image__img,
  .van-image__loading {
    display: block;
    width: 100%;
    height: 100%;
  }
  .agreement {
    margin-top: 15%;
    padding-bottom: 3%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    /deep/.van-icon {
      height: 1rem;
      width: 1rem;
      font-size: 0.66667rem;
      color: #fff !important;
      border-radius: 4px;
    }
    /deep/.van-checkbox__label {
      margin-left: 3px;
      margin-right: 3px;
    }
    .van-checkbox__icon--checked .van-icon {
      color: #fff !important;
      background-color: #004ea3;
      border-color: #004ea3;
      border-radius: 4px;
      font-size: 0.66667rem;
    }
  }
  .iconfont {
    color: #cad0e6;
    font-size: 18px;
  }
}
</style>
